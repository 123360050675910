import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import achievements from "../achievements";
import ndjsonStream from "can-ndjson-stream";
import { Link } from "react-router-dom";
import { getLevelByXp } from "../utils/levelUtils";
import promo1 from "../icons/promo-1.png";
import promo2 from "../icons/promo-2.png";
import promo3 from "../icons/promo-3.png";
import { client } from "../sanity";
const BlockContent = require("@sanity/block-content-to-react");

function Tabell({ spillere, alle_turneringer }) {
  const [ratinggruppe, setRatinggruppe] = useState("alle");
  const [nesteTurneringer, setNesteTurneringer] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchPosts = () => {
      client
        .fetch(`*[_type == $type][0]`, {
          type: "frontpage",
        })
        .then((res) => {
          setInfo(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.error("Oh no, error occured: ", err);
        });
    };
    fetchPosts();
  }, []);

  spillere.forEach((el, index) => {
    el.prevFullScoreRank = index + 1;
  });

  spillere.forEach((el, index) => {
    el.xp = 0;
    let tempLevel = 0;

    achievements
      .sort((a, b) => (a.lvlReq > b.lvlReq ? 1 : -1))
      .map((achievement) => {
        tempLevel = getLevelByXp(el.xp);

        if (
          achievement.check(el, alle_turneringer, 0) &&
          tempLevel >= achievement.lvlReq
        ) {
          el.xp += achievement.xp;
        }
      });

    el.level = getLevelByXp(el.xp);
  });

  spillere.sort((a, b) => b.xp - a.xp);

  let filtrerteSpillere = spillere;

  if (ratinggruppe !== "alle") {
    filtrerteSpillere = spillere.filter(
      (spiller) => spiller.ratingGroup === ratinggruppe
    );
  }

  const last_tournament_date =
    alle_turneringer[alle_turneringer.length - 1].date;

  const handleSelectChange = (event) => {
    switch (event.target.value) {
      case "alle":
        setRatinggruppe("alle");
        break;
      case "U2000":
        setRatinggruppe("U2000");
        break;
      case "U1500":
        setRatinggruppe("U1500");
    }
  };

  useEffect(() => {
    async function fetchData() {
      fetch(
        "https://lichess.org/api/user/offerspillsk/tournament/created?nb=10"
      )
        .then((response) => {
          return ndjsonStream(response.body);
        })
        .then((exampleStream) => {
          let read;
          let tournaments = [];
          const reader = exampleStream.getReader();

          reader
            .read()
            .then(function processValue({ done, value }) {
              // Result objects contain two properties:
              // done  - true if the stream has already given you all its data.
              // value - some data. Always undefined when done is true.
              if (done) {
                return tournaments;
              }

              if (value.fullName.includes("League")) {
                tournaments.push(value);
              }

              // Read some more, and call this function again
              return reader.read().then(processValue);
            })
            .then((data) => {
              setNesteTurneringer(tournaments);
            });
        });
    }

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  nesteTurneringer.sort((a, b) => a.startsAt - b.startsAt);

  const t = Math.round(new Date().getTime());

  const currentTurnering = nesteTurneringer.find(
    (turnering) => t < turnering.finishesAt && t > turnering.startsAt
  );
  const kommendeTurneringer = nesteTurneringer.filter(
    (turnering) =>
      t < turnering.startsAt && turnering.fullName.includes("League")
  );

  return (
    <>
      <ReactTooltip />
      <img className="logo-frontpage" alt="Logo" src="league_logo.png" />
      <br />
      {currentTurnering ? (
        <div className="live-turneringer">
          <div className="glowing-circle"></div>
          <h3 className="current-turnering">
            Now playing:{" "}
            <a
              href={"https://lichess.org/tournament/" + currentTurnering.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {currentTurnering.fullName}
            </a>
          </h3>
        </div>
      ) : null}
      {/*<div className="kommende-turneringer alert">
        We're having some technical problems. The achievements will be updated
        as soon as possible.
      </div>*/}
      {kommendeTurneringer.length ? (
        <div className="kommende-turneringer">
          {kommendeTurneringer.length === 1 ? (
            <h4>Next tournament</h4>
          ) : (
            <h4>Upcoming League tournaments:</h4>
          )}
          {kommendeTurneringer.map((turnering) => (
            <div>
              {moment(turnering.startsAt).format("MMMM Do, HH:mm")}:{" "}
              <a
                href={"https://lichess.org/tournament/" + turnering.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                {turnering.fullName}
              </a>
            </div>
          ))}
        </div>
      ) : null}
      <div
        style={{
          marginBottom: "0.5rem",
          fontStyle: "italic",
          fontSize: "14px",
        }}
      >
        {/*Last updated {moment(last_tournament_date).format("MMMM Do")}*/}
      </div>
      <div className="top-section">
        <div className="info">
          <BlockContent blocks={info.content} />
        </div>
        <div className="promo">
          <div className="promo-element">
            <img alt="Tournament table" src={promo1} height="200px"></img>
            <span>Play tournaments to earn achievements</span>
          </div>
          <div className="promo-element">
            <img alt="Trend" src={promo2} height="200px"></img>
            <span>Level up</span>
          </div>
          <div className="promo-element">
            <img alt="Gift" src={promo3} height="200px"></img>
            <span>Win prizes</span>
          </div>
        </div>
      </div>
      <h2 className="page-title">Standings</h2>
      <div className="col-md-4 wrap-select" style={{ padding: 0 }}>
        <p className="sorter-tekst">Sort by rating group:</p>
        <select
          className="velg-ratinggruppe form-control mb-2 mr-sm-2"
          onChange={handleSelectChange}
        >
          <option value="alle">All players</option>
          <option value="U2000">U2000</option>
          <option value="U1500">U1500</option>
        </select>
      </div>
      <table
        id="scoreboard"
        className="scoreboard table striped table-responsive-md"
      >
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>#</th>
            <th style={{ textAlign: "left" }}>Username</th>
            <th style={{ textAlign: "right" }}>Level</th>
            <th style={{ textAlign: "right" }}>XP</th>
            <th style={{ textAlign: "right" }}>Number of tournaments played</th>
            <th style={{ textAlign: "right" }}>Best tournament finish</th>
          </tr>
        </thead>
        <tbody>
          {filtrerteSpillere.map((player, i) => {
            const min_rank = player.tournaments
              ? Math.min(...player.tournaments.map((o) => o.rank), 10000)
              : 0;
            const num_tournaments = player.tournaments
              ? player.tournaments.length
              : 0;

            return (
              <tr>
                <td>
                  <div className="rank-wrapper">
                    <span
                      style={{ display: "inline-block", textAlign: "left" }}
                    >
                      <b>{i + 1}</b>
                    </span>
                  </div>
                </td>
                <td style={{ textAlign: "left" }}>
                  <Link to={"/player/" + player.username}>
                    {player.username}
                  </Link>{" "}
                  <span
                    className="rating"
                    title="Høyeste rating som er observert"
                  >
                    <i>{player.maxRating}</i>
                  </span>
                </td>
                <td style={{ textAlign: "right" }}>{player.level}</td>
                <td style={{ textAlign: "right" }}>{player.xp}</td>
                <td style={{ textAlign: "right" }}>{num_tournaments}</td>
                <td style={{ textAlign: "right" }}>{min_rank}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default Tabell;
