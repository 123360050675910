import React from "react";

import "../../App.scss";
import beforeFilterAchievements from "../../achievements";
import AchievementBadge from "./AchievementBadge";
import { getLevelByXp } from "../../utils/levelUtils";
var _ = require("lodash/core");

function Achievements({
  player,
  alle_turneringer,
  cumScores,
  setXp,
  setLevel,
}) {
  const achievements = beforeFilterAchievements.filter(
    (achievement) => !achievement.fr_specific
  );

  const oppnadde = achievements.filter((achievement) =>
    achievement.check(player)
  ).length;

  const DISABLE_FR_ACHIEVEMENTS = true;

  let xp = 0;
  let level = 1;

  achievements
    .sort((a, b) => (a.lvlReq > b.lvlReq ? 1 : -1))
    .map((achievement) => {
      level = getLevelByXp(xp);

      if (
        achievement.check(player, alle_turneringer, cumScores) &&
        level >= achievement.lvlReq
      ) {
        xp += achievement.xp;
      }
    });

  setXp(xp);
  setLevel(level);

  const allGroupedAchievements = achievements.reduce(function (r, a) {
    r[a.lvlReq] = r[a.lvlReq] || [];
    r[a.lvlReq].push(a);
    return r;
  }, Object.create(null));

  const groupedAchievements = achievements.reduce(function (r, a) {
    if (level >= a.lvlReq) {
      r[a.lvlReq] = r[a.lvlReq] || [];
      r[a.lvlReq].push(a);
    }
    return r;
  }, Object.create(null));

  const keysGroupedAchievements = Object.keys(groupedAchievements);
  const keysAllGroupedAchievements = Object.keys(allGroupedAchievements);

  let nextUnlock = -1;
  let numberOfUnlocks = -1;

  if (keysGroupedAchievements.length < keysAllGroupedAchievements.length) {
    nextUnlock = keysAllGroupedAchievements[keysGroupedAchievements.length];
    numberOfUnlocks = allGroupedAchievements[nextUnlock].length;
  }

  return (
    <div className="achievement-section">
      {Object.keys(groupedAchievements).map((key, index) => {
        return (
          <div key={index}>
            <h4 className="level-section unlocked">Level {key} achievements</h4>
            <div className="achievement-container">
              {groupedAchievements[key]
                .sort((a, b) => (a.xp > b.xp ? 1 : -1))
                .map((achievement) => {
                  if (
                    achievement.enabled === false ||
                    (DISABLE_FR_ACHIEVEMENTS && achievement.fr_specific)
                  )
                    return null;

                  return (
                    <>
                      <AchievementBadge
                        achievement={achievement}
                        player={player}
                        alle_turneringer={alle_turneringer}
                        cumScores={cumScores}
                        level={level}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        );
      })}
      {nextUnlock === -1 ? (
        <h4 className="unlocked">No more achievements to unlock!</h4>
      ) : (
        <h4>
          {numberOfUnlocks} new achievements will be unlocked at level{" "}
          {nextUnlock}…
        </h4>
      )}
    </div>
  );
}

export default Achievements;
