export const levels = [
  200,
  700,
  2500,
  3200,
  4400,
  9000,
  14000,
  20000,
  25000,
  32000,
  40000,
  15000,
  999999999999,
];

export const getPercentageToNextLevel = (xp) => {
  const index = levels.findIndex((num) => {
    return num >= xp;
  });

  const moreThan = index > 0 ? levels[index - 1] : 0;
  const lessThan = levels[index];

  const percentage = parseInt(
    ((xp - moreThan) / (lessThan - moreThan)) * 100,
    10
  );

  return percentage;
};

export const getXpToNextLevel = (xp) => {
  const index = levels.findIndex((num) => {
    return num >= xp;
  });

  const lessThan = levels[index];

  return lessThan - xp;
};

export const getNextLevelXp = (xp) => {
  const index = levels.findIndex((num) => {
    return num >= xp;
  });

  return levels[index];
};

export const getLevelByXp = (xp) => {
  const index = levels.findIndex((num) => {
    return num >= xp + 1;
  });

  return index + 1;
};
