import medal1 from "./icons/medal.png";
import multitalent from "./icons/multitalent.png";
import second from "./icons/second.png";
import award from "./icons/award.png";
import third from "./icons/third.png";
import fischer from "./icons/fischer.png";
import bulletbill from "./icons/bulletbill.png";
import shark from "./icons/shark.png";
import pilopp from "./icons/top-right.png";
import ti from "./icons/10.png";
import tjue from "./icons/20.png";
import tretti from "./icons/30.png";
import femti from "./icons/50.png";
import hundre from "./icons/100.png";
import slingshot from "./icons/slingshot.png";
import boxer from "./icons/boxer.png";
import gm from "./icons/crown.png";
import im from "./icons/quality.png";
import fm from "./icons/radio.png";
import cm from "./icons/cm.png";
import giri from "./icons/giri.png";
import arm from "./icons/robot-arm.png";
import king from "./icons/king.png";
import gepard from "./icons/cheetah.png";
import bnils from "./icons/bnils.png";
import orangutan from "./icons/orangutan.png";
import winorangutan from "./icons/winorangutan.png";
import gg from "./icons/gg.png";
import wingg from "./icons/wingg.png";
import shock from "./icons/shock.png";
import langrokade from "./icons/langrokade.png";
import kortrokade from "./icons/kortrokade.png";
import rogue from "./icons/rogue.png";
import fox from "./icons/fox.png";
import soze from "./icons/soze.png";
import fight from "./icons/fight.png";
import promotion from "./icons/promotion.png";
import knightmate from "./icons/knightmate.png";
import bishopknight from "./icons/bishopknight.png";
import rock from "./icons/rock.png";
import point from "./icons/point.png";
import strat from "./icons/strat.png";
import hugh from "./icons/hugh.png";
import horse from "./icons/horse.png";
import gun from "./icons/gun.png";
import french from "./icons/french.png";
import mafia from "./icons/mafioso.png";
import sangria from "./icons/sangria.png";
import queen from "./icons/queen.png";
import anand from "./icons/anand.png";
import dominance from "./icons/dominance.png";
import london from "./icons/london.png";
import kg from "./icons/kg.png";
import composer from "./icons/composer.png";
import bongcloud from "./icons/bongcloud.png";
import god from "./icons/god.png";
import baby from "./icons/baby.png";
import gettingthere from "./icons/gettingthere.png";
import growing from "./icons/growing.png";
import talking from "./icons/chat.png";
import cashing from "./icons/cashing.png";
import porridge from "./icons/porridge.png";
import snore from "./icons/knot.png";
import glue from "./icons/glue.png";
import superglue from "./icons/sglue.png";
import troll from "./icons/troll.png";
import cross from "./icons/cross.png";
import keeper from "./icons/keeper.png";
import gandalf from "./icons/gandalf.png";
import pusheen from "./icons/pusheen.png";
import two from "./icons/two.png";
import three from "./icons/three.png";
import four from "./icons/four.png";
import five from "./icons/five.png";
import stroller from "./icons/stroller.png";

export default [
  {
    title: "NILSEN",
    image: bnils,
    description: "Score higher than IM Joachim Nilsen (JoaBN) in a tournament",
    secret: false,
    fr_specific: false,
    enabled: true,
    check: function (player, alle_turneringer, cumScores) {
      if (!alle_turneringer) return false;
      return alle_turneringer.find((turnering) => {
        const spillere = turnering.players;
        if (spillere.indexOf(player.username) === -1) return false;

        return spillere.indexOf(player.username) < spillere.indexOf("JoaBN");
      });
    },
    lvlReq: 1,
    xp: 50,
  },
  {
    title: "STARTED",
    image: medal1,
    description: "Play one tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length;
    },
    xp: 10,
    lvlReq: 1,
  },
  {
    title: "GOLDEN",
    image: medal1,
    description: "Win a tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces;
    },
    xp: 1500,
    lvlReq: 7,
  },
  {
    title: "SØLV",
    image: second,
    description: "Finish second",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.secondPlaces;
    },
    xp: 800,
    lvlReq: 5,
  },
  {
    title: "BRONSE",
    image: third,
    description: "Finish third",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.thirdPlaces;
    },
    xp: 300,
    lvlReq: 3,
  },
  {
    title: "MULTITALENT",
    image: multitalent,
    description: "Play on three different days of the week",
    secret: false,
    fr_specific: true,
    check: function (player) {
      return (
        player.tournaments?.find((t) => t.key === "bullet") &&
        player.tournaments?.find((t) => t.key === "blitz") &&
        player.tournaments?.find((t) => t.key === "chess960")
      );
    },
    xp: 50,
    lvlReq: 1,
  },
  {
    title: "MESTER",
    image: award,
    description: "Finish among the top 3 in 5 tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      const pallPlasseringer =
        player.firstPlaces + player.secondPlaces + player.thirdPlaces;
      return pallPlasseringer > 4;
    },
    xp: 2000,
    lvlReq: 7,
  },
  {
    title: "UNDERDOG",
    image: slingshot,
    description: "Beat a higher rated player",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.beaten_higher_rated;
    },
    xp: 50,
    lvlReq: 1,
  },
  {
    title: "LYNHAI",
    image: shark,
    description: "Win a Blitz tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.filter((t) => t.key === "blitz" && t.rank === 1)
        .length;
    },
    xp: 1000,
    lvlReq: 5,
  },
  {
    title: "BULLET BILL",
    image: bulletbill,
    description: "Win a Bullet tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.key === "bullet" && t.rank === 1);
    },
    xp: 1000,
    lvlReq: 5,
  },
  {
    title: "FISCHER",
    image: fischer,
    description: "Vinn en Fischersjakkturnering.",
    secret: false,
    fr_specific: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "chess960" && t.rank === 1
      );
    },
    xp: 300,
    lvlReq: 3,
  },
  {
    title: "PERFORMANCE",
    image: pilopp,
    description: "Perform higher than your rating in a tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.performance - t.rating > 0);
    },
    xp: 250,
    lvlReq: 3,
  },
  {
    title: "TEN",
    image: ti,
    description: "Get at least 10 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 9);
    },
    xp: 20,
    lvlReq: 1,
  },
  {
    title: "TWENTY",
    image: tjue,
    description: "Get at least 20 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 19);
    },
    xp: 20,
    lvlReq: 1,
  },
  {
    title: "THIRTY",
    image: tretti,
    description: "Get at least 30 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 29);
    },
    xp: 90,
    lvlReq: 2,
  },
  {
    title: "FIFTY",
    image: femti,
    description: "Get at least 50 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 49);
    },
    xp: 300,
    lvlReq: 3,
  },
  {
    title: "HUNDRED",
    image: hundre,
    description: "Get at least 100 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 99);
    },
    xp: 500,
    lvlReq: 5,
  },
  {
    title: "TITTELKNUSER",
    image: boxer,
    description: "Slå en tittelspiller.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_titled;
    },
    xp: 800,
    lvlReq: 5,
  },
  {
    title: "ORANGUTANG",
    image: orangutan,
    description: "Play 1. b4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_b4;
    },
    xp: 80,
    lvlReq: 1,
  },
  {
    title: "GG",
    image: gg,
    description: "Play 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_g4;
    },
    xp: 80,
    lvlReq: 1,
  },
  {
    title: "GOLDEN ORANGUTANG",
    image: winorangutan,
    description: "Win with 1. b4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_b4;
    },
    xp: 150,
    lvlReq: 3,
  },
  {
    title: "GOLDEN GG",
    image: wingg,
    description: "Win with 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_g4;
    },
    xp: 150,
    lvlReq: 5,
  },
  {
    title: "KING",
    image: gm,
    description: "Beat a GM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_gm;
    },
    xp: 1500,
    lvlReq: 7,
  },
  {
    title: "PRINCE",
    image: im,
    description: "Beat an IM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_im;
    },
    lvlReq: 7,
    xp: 1300,
  },
  {
    title: "DAB",
    image: fm,
    description: "Beat an FM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_fm;
    },
    lvlReq: 5,
    xp: 800,
  },
  {
    title: "CM BEATER",
    image: cm,
    description: "Beat a CM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_cm;
    },
    lvlReq: 3,
    xp: 400,
  },
  {
    title: "WTF",
    image: shock,
    description: "Spill Fischersjakk fra vanlig utgangsstilling.",
    secret: false,
    fr_specific: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.fr_vanlig;
    },
    xp: 1000,
  },
  {
    title: "LAAANG ROKADE",
    image: langrokade,
    description: "Utfør den lengste mulige rokade i Fischersjakk.",
    secret: false,
    fr_specific: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.fr_castle_1;
    },
    xp: 500,
  },
  {
    title: "1. 0-0",
    image: kortrokade,
    description: "Utfør rokade i det første trekket i Fischersjakk.",
    secret: false,
    fr_specific: true,
    check: function (player, alle_turneringer, cumScores) {
      return player.fr_castle_0;
    },
    xp: 500,
  },
  {
    title: "GIRI",
    image: giri,
    description: "Spill remis i alle partiene i en turnering.",
    secret: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.drawn_all_games;
    },
    lvlReq: 10,
    xp: 5000,
  },
  {
    title: "WOW",
    image: arm,
    description: "Beat a player rated at least 300 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_300_higher_rated;
    },
    lvlReq: 5,
    xp: 300,
  },
  {
    title: "CHEETAH",
    image: gepard,
    description: "Play 40+ games in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.flest_partier_paa_en_turnering > 39;
    },
    lvlReq: 5,
    xp: 1000,
  },
  {
    title: "IMPOSSIBLE",
    image: king,
    description: "Beat Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_magnus;
    },
    lvlReq: 10,
    xp: 8000,
  },
  {
    title: "PLAYER",
    image: rogue,
    description: "Play 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 99;
    },
    lvlReq: 3,
    xp: 300,
  },
  {
    title: "FOX",
    image: fox,
    description: "Play 300 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 299;
    },
    lvlReq: 5,
    xp: 800,
  },
  {
    title: "KEYSER SÖZE",
    image: soze,
    description: "Slå alle brikkene til motstanderen.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_brikker_slaatt == 15;
    },
    lvlReq: 5,
    xp: 700,
  },
  {
    title: "FIGHT CLUB",
    image: fight,
    description: "Capture 8 moves in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_slag_paa_rad > 7;
    },
    lvlReq: 5,
    xp: 800,
  },
  {
    title: "PROMOTION",
    image: promotion,
    description: "Promote at least 4 pawns during one game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_number_of_promotions > 3;
    },
    lvlReq: 5,
    xp: 700,
  },
  {
    title: "KNIGHTMARE",
    image: knightmate,
    description: "Promote to knight with mate",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.wowowK;
    },
    lvlReq: 10,
    xp: 6500,
  },
  {
    title: "TEAMWORK",
    image: bishopknight,
    description: "Mate with bishop and knight",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.mate_with_bishop_and_knight;
    },
    lvlReq: 7,
    xp: 1200,
  },
  {
    title: "ROCK OF GIBRALTAR",
    image: rock,
    description: "Play the Caro-Kann",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c6;
    },
    lvlReq: 1,
    xp: 40,
  },
  {
    title: "TO THE POINT",
    image: point,
    description: "Play 1. e4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e4;
    },
    lvlReq: 1,
    xp: 20,
  },
  {
    title: "STRATEGIST",
    image: strat,
    description: "Play 1. d4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_d4;
    },
    lvlReq: 1,
    xp: 20,
  },
  {
    title: "HUGH GRANT",
    image: hugh,
    description: "Play 1. c4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c4;
    },
    lvlReq: 1,
    xp: 20,
  },
  {
    title: "THE HORSE WHISPERER",
    image: horse,
    description: "Play 1. Nf3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf3;
    },
    lvlReq: 1,
    xp: 20,
  },
  {
    title: "THE GUN",
    image: gun,
    description: "Play Alekhine's Defense",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf6;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "THE SHORTCUT",
    image: french,
    description: "Play the French",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e6;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "MAFIA",
    image: mafia,
    description: "Play the Sicilian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c5;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "SANGRIA",
    image: sangria,
    description: "Play the Spanish",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_spanish;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: 'THE "GAMBIT"',
    image: queen,
    description: "Play the Queen's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_qg;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "ANAND",
    image: anand,
    description: "Play the Nimzo-Indian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nimzo;
    },
    lvlReq: 3,
    xp: 100,
  },
  {
    title: "DOMINANCE",
    image: dominance,
    description: "Win without losing a single piece",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken === 0;
    },
    lvlReq: 7,
    xp: 1337,
  },
  {
    title: "THE HAUGEN",
    image: london,
    description: "Play the London",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_london;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "HAARRIFIED",
    image: kg,
    description: "Play the King's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_kg;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "COMPOSER",
    image: composer,
    description: "Play the Philidor Defence",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_philidor;
    },
    lvlReq: 2,
    xp: 50,
  },
  {
    title: "SHORT AND SWEET",
    image: kortrokade,
    description: "Castle at move 4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.short_castle_4;
    },
    lvlReq: 2,
    xp: 60,
  },
  {
    title: "LONG AND SWEET",
    image: langrokade,
    description: "Queenside castle at move 5",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.long_castle_5;
    },
    lvlReq: 2,
    xp: 70,
  },
  {
    title: "BONGCLOUD",
    image: bongcloud,
    description: "Win with the Bongcloud (1. e4 2. Ke2)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_bongcloud;
    },
    lvlReq: 7,
    xp: 1000,
  },
  {
    title: "GREEK GOD",
    image: god,
    description: "Win with The Greek (1. f3 2. Kf2)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_greek;
    },
    lvlReq: 7,
    xp: 1000,
  },
  {
    title: "BABY STEPS",
    image: baby,
    description: "Win a game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 0;
    },
    lvlReq: 1,
    xp: 30,
  },
  {
    title: "GROWING",
    image: growing,
    description: "Win 10 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 9;
    },
    lvlReq: 2,
    xp: 70,
  },
  {
    title: "GETTING THERE",
    image: gettingthere,
    description: "Win 30 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 29;
    },
    lvlReq: 3,
    xp: 300,
  },
  {
    title: "NOW WE'RE TALKING",
    image: talking,
    description: "Win 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 99;
    },
    lvlReq: 5,
    xp: 500,
  },
  {
    title: "CASHING IN",
    image: cashing,
    description: "Win 250 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 249;
    },
    lvlReq: 7,
    xp: 1000,
  },
  {
    title: "ANGRY ON THE PORRIDGE",
    image: porridge,
    description: "Give check 3 times within the first 10 moves",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.num_checks_10 > 2;
    },
    lvlReq: 3,
    xp: 300,
  },
  {
    title: "HANGING SNORE",
    image: snore,
    description: "Get stalemated",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.num_checks_10 > 2;
    },
    lvlReq: 3,
    xp: 300,
  },
  {
    title: "GLUE",
    image: glue,
    description: "Play more than 60 moves in one game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_trekk_parti > 60;
    },
    lvlReq: 3,
    xp: 200,
  },
  {
    title: "SUPERGLUE",
    image: superglue,
    description: "Play more than 100 moves in one game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_trekk_parti > 100;
    },
    lvlReq: 7,
    xp: 1000,
  },
  {
    title: "LEFONG",
    image: troll,
    description: "Win after a succeeded Lefong",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.lefong;
    },
    lvlReq: 7,
    xp: 1100,
  },
  {
    title: "GOOD LUCK",
    image: cross,
    description: "Play Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_magnus;
    },
    lvlReq: 1,
    xp: 80,
  },
  {
    title: "GANDALF",
    image: gandalf,
    description: "Play Andrew Tang (penguingm1)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_penguin;
    },
    lvlReq: 1,
    xp: 50,
  },
  {
    title: "MEOW",
    image: pusheen,
    description: "Play PusheenMeow",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_pusheen;
    },
    lvlReq: 1,
    xp: 50,
  },
  {
    title: "KEEPER",
    image: keeper,
    description: "Keep at least 13 pieces (and win)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken < 4;
    },
    lvlReq: 3,
    xp: 250,
  },
  {
    title: "STREAK",
    image: two,
    description: "Win 2 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 1;
    },
    lvlReq: 2,
    xp: 60,
  },
  {
    title: "STREEAK",
    image: three,
    description: "Win 3 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 2;
    },
    lvlReq: 3,
    xp: 300,
  },
  {
    title: "STREEEAK",
    image: four,
    description: "Win 4 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 3;
    },
    lvlReq: 5,
    xp: 900,
  },
  {
    title: "STREEEEAK",
    image: five,
    description: "Win 5 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 4;
    },
    lvlReq: 7,
    xp: 2000,
  },
  {
    title: "ADOPTION",
    image: stroller,
    description: "Win 10 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 9;
    },
    lvlReq: 10,
    xp: 8000,
  },
];
