import React, { useState, useEffect } from "react";
import { client } from "../sanity";
import "../App.scss";
const BlockContent = require("@sanity/block-content-to-react");

function Om() {
  const [about, setAbout] = useState({});

  useEffect(() => {
    const fetchPosts = () => {
      client
        .fetch(`*[_type == $type][0]`, {
          type: "about",
        })
        .then((res) => {
          setAbout(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.error("Oh no, error occured: ", err);
        });
    };
    fetchPosts();
  }, []);

  return (
    <div className="om">
      <BlockContent blocks={about.content} />
    </div>
  );
}

export default Om;
