import React from "react";

import "../../App.scss";
import achievements from "../../achievements";
import { getLevelByXp } from "../../utils/levelUtils";

function AchievementBadge({
  achievement,
  player,
  alle_turneringer,
  cumScores,
  level,
}) {
  const achieved = achievement.check(player, alle_turneringer, cumScores);

  const hemmelig = achievement.secret && !achieved;

  const titleClass = achieved
    ? "achievement-title achieved"
    : "achievement-title";

  return (
    <div key={achievement.title} className="achievement-element">
      <div className="xptittel">
        <p className="xp">XP: {achievement.xp}</p>
        <p className={titleClass}>{!hemmelig ? achievement.title : "?"}</p>
      </div>
      <img
        src={achievement.image}
        className={achieved ? "jepp" : "nope"}
        height="64px"
      ></img>
      <p className="achievement-description">
        {!hemmelig ? achievement.description : "Secret until achieved!"}
      </p>
    </div>
  );
}

export default AchievementBadge;
